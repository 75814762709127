import { CssBaseline, Typography } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { muiTheme } from './theme.styles';

export const Theme: FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={muiTheme}>
      {/* Typography is used here in order to have access to typography classes without using Typography component */}
      <Typography />
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);
