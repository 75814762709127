import { DEFAULT_LANGUAGE, timedFunction } from '@wr/web-shared';
import { AppContextProviderShared, Theme } from '@wr/web-ui';
import { promises } from 'fs';
import { GetStaticProps } from 'next';
import { FC } from 'react';

import { Layout, PageMetadataComponent } from '@/components';
import { DEFAULT_404_PAGE_SLUG } from '@/constants';
import { AppContextProvider } from '@/context';
import { getHeader } from '@/services/contentful/header';
import { getPage } from '@/services/contentful/page';
import { PageProps } from '@/types';
import { getAllModuleItems } from '@/utils';
import { resolveComponents } from '@/utils/component-resolver';

import { AppPropsWithLayout } from './_app';

const NotFoundPage: FC<PageProps & AppPropsWithLayout> = ({
  modules,
  originUri,
  regions = [],
  title,
  description,
  pathname,
  footer,
  header,
  isWebView,
}) => {
  const resolvedComponents = resolveComponents(modules || []);

  return (
    <AppContextProviderShared
      regions={regions as string[]}
      originUri={originUri}
    >
      <AppContextProvider>
        <PageMetadataComponent
          breadcrumbs={null}
          title={title}
          description={description}
          originUri={originUri}
          pathname={pathname || ''}
          modules={null}
          organizationSchema={null}
          newsArticleSchema={null}
        />
        <Theme>
          <Layout
            header={isWebView ? {} : { ...header, hideRegionSelector: true }}
            footer={footer}
          >
            {resolvedComponents}
          </Layout>
        </Theme>
      </AppContextProvider>
    </AppContextProviderShared>
  );
};

export default NotFoundPage;

type NotFoundPage =
  | Pick<
      PageProps,
      | 'name'
      | 'slug'
      | 'title'
      | 'description'
      | 'regions'
      | 'noIndex'
      | 'header'
      | 'footer'
      | 'modules'
      | 'preview'
      | 'pathname'
      | 'originUri'
    >
  | Record<string, never>;

export const getStaticProps: GetStaticProps<
  NotFoundPage | Record<string, unknown>
> = async ({ locale: nextLocale, defaultLocale, preview = false }) => {
  const fileUtils = {
    readFile: promises.readFile,
    writeFile: promises.writeFile,
    mkdir: promises.mkdir,
  };

  const locale =
    (nextLocale === defaultLocale ? DEFAULT_LANGUAGE : nextLocale) ||
    DEFAULT_LANGUAGE;

  const page = await getPage({
    slug: DEFAULT_404_PAGE_SLUG,
    locale,
    preview,
    ...fileUtils,
  });

  if (!page) {
    return {
      props: {},
    };
  }

  const getPageData = async () => {
    return Promise.all([
      getHeader({
        locale,
        preview,
        ...fileUtils,
      }),
      getAllModuleItems({
        modules: page?.modulesCollection?.items,
        sendCountryCode: '',
        receiveCountryCode: '',
        locale,
        preview,
        ...fileUtils,
      }) || null,
    ]);
  };

  const [header, modules] = await timedFunction(
    'getStaticProps',
    getPageData,
  )();

  return {
    props: {
      name: page.name,
      slug: page.slug,
      title: page.title,
      description: page.description,
      noIndex: page.noIndex,
      header: {
        ...header,
        headerPosition: page.headerPosition,
        headerTheme: page.headerTheme,
      },
      footer: page.footer,
      modules,
      preview,
      regions: [],
      pathname: '404',
      originUri: process.env.WEB_CMS_URI,
    },
  };
};
